import React from 'react'
import T from 'prop-types'
import * as M from 'Modules'
import PortfolioItem from './PortfolioItem'
import SEO from 'Layout/SEO'
import styles from './PortfolioPage.module.css'

export const PortfolioPageTemplate = ({items}) => (
  <>
    <section className={styles.container}>
      {items.map((item, index) => <PortfolioItem key={index} id={index} {...item}/>)}
    </section>
    <M.ScrollUpButton/>
  </>
)

PortfolioPageTemplate.propTypes = {items: T.arrayOf(T.object.isRequired).isRequired}

export const PortfolioPage = props => (
  <>
    <SEO {...props.data.markdownRemark.frontmatter.seo} {...props}/>
    <PortfolioPageTemplate items={props.data.markdownRemark.frontmatter.items}/>
  </>
)

PortfolioPage.propTypes = {
  data: T.shape({
    markdownRemark: T.shape({
      frontmatter: T.shape({
        items: T.array.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export default PortfolioPage
