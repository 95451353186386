import React from 'react'
import T from 'prop-types'
import cx from 'classnames'
import Img from 'gatsby-image/withIEPolyfill'
import Modal from 'UI/Modal'
import Markdown from 'react-markdown'
import {compose, setPropTypes, withState, lifecycle, withHandlers} from 'recompose'
import withTouch from 'HOC/withTouch'
import styles from './PortfolioItem.module.css'

export const Slider = compose(
  withHandlers({onFollowTouchEnd: props => followedX => followedX > 30 ? props.onNext() : followedX < -30 ? props.onPrev() : null}),
  withTouch(props => `portfolio-slider-${props.id}`)
)(({id, activeIndex, images, title, followedX}) => (
  <section className={cx(styles.slider, {[styles.drag]: Boolean(followedX)})} style={{
    transform: `translateX(${(activeIndex * 100 * -1) + (followedX * -1)}%)`,
    transition: followedX ? 'none' : '',
  }} id={`portfolio-slider-${id}`}>
    {(images || []).map((image, i) => (
      <Img
        key={i}
        draggable={false}
        objectFit="contain"
        objectPosition="50% 50%"
        className={styles.slide}
        alt={title}
        title={title}
        fluid={image.childImageSharp.fluid}
        />
    ))}
  </section>
))

export const PortfolioItem = ({id, images, feature, title, author, properties, activeIndex, isOpen, onOpen, onClose, onClick, onNext, onPrev}) => (
  <article className={cx({[styles.clickable]: (images || []).length > 0})}>
    <div onClick={(images || []).length > 0 ? onOpen : null}>
      <Img
        objectFit="cover"
        objectPosition="50% 50%"
        className={styles.image}
        alt={title}
        title={title}
        fluid={feature.childImageSharp.fluid}
        backgroundColor={(feature.colors && feature.colors.vibrant) || '#fff'}
      />
    </div>
    <h1 className={styles.title}>{title}</h1>
    <h2 className={styles.author}>{author}</h2>
    <Markdown className={styles.properties} source={properties}/>

    {isOpen ? (
      <Modal>
        <section className={styles.modal}>
          <section className={styles.modalwrapper}>
            <div className={styles.overlay} onClick={onClose}/>
            <section className={styles.container}>
              <section className={styles.wrapper}>
                <Slider id={id} activeIndex={activeIndex} images={images} title={title} onNext={onNext} onPrev={onPrev}/>
              </section>

              <button type="button" className={styles.close} onClick={onClose}>
                <svg viewBox="0 0 22 22">
                  <g transform="translate(-1 -1)" fillRule="evenodd">
                    <rect transform="rotate(45 12 12)" x="11" y="-3" width="2" height="30" rx="1"/>
                    <rect transform="scale(-1 1) rotate(45 0 -16.97)" x="11" y="-3" width="2" height="30" rx="1"/>
                  </g>
                </svg>
              </button>
              {(images || []).length <= 1 ? null : (
                <>
                  <button type="button" className={cx(styles.arrow, styles.left)} onClick={onPrev}>
                    <svg viewBox="0 0 31 58">
                      <path d="M30 57L2 27.353 30 1" stroke="#000" strokeWidth="2" fill="none" strokeLinecap="round"/>
                    </svg>
                  </button>
                  <button type="button" className={cx(styles.arrow, styles.right)} onClick={onNext}>
                    <svg viewBox="0 0 32 58">
                      <path d="M1 1l28 29.647L1 57" stroke="#000" strokeWidth="2" fill="none" strokeLinecap="round"/>
                    </svg>
                  </button>
                </>
              )}
              {(images || []).length <= 1 ? null : (
                <div className={styles.nav}>{`${activeIndex + 1}/${images.length}`}</div>
              )}
            </section>
          </section>
        </section>
      </Modal>
    ) : null}
  </article>
)

export const enhance = compose(
  withState('isOpen', 'toggleSlider', false),
  withState('activeIndex', 'changeActiveIndex', 0),
  withHandlers({
    onOpen: props => () => {
      document.body.classList.add('overlayed')
      props.toggleSlider(true)
    },
    onClose: props => () => {
      document.body.classList.remove('overlayed')
      props.toggleSlider(false)
    },
    onNext: props => () => props.changeActiveIndex(props.activeIndex + 1 > props.images.length - 1 ? 0 : props.activeIndex + 1),
    onPrev: props => () => props.changeActiveIndex(props.activeIndex - 1 < 0 ? props.images.length - 1 : props.activeIndex - 1),
  }),
  withHandlers({
    onKeydown: props => ev => {
      if (!props.isOpen) return;

      const fn = {
        'Escape': props.onClose,
        'ArrowLeft': props.onPrev,
        'ArrowRight': props.onNext,
      }[ev.code];

      if (fn) fn();
    },
  }),
  lifecycle({
    componentDidMount() {
      if ((this.props.images || []).length > 0) {
        window.addEventListener('keydown', this.props.onKeydown, {passive: true});
      }
    },
    componentWillUnmount() {
      if ((this.props.images || []).length > 0) {
        window.removeEventListener('keydown', this.props.onKeydown, {passive: true});
      }
    },
  }),
  setPropTypes({
    isOpen: T.bool.isRequired,
    title: T.string.isRequired,
    author: T.string.isRequired,
    properties: T.string.isRequired,
    feature: T.shape({
      childImageSharp: T.object.isRequired,
    }).isRequired,
    images: T.arrayOf(T.shape({
      childImageSharp: T.object.isRequired,
    }).isRequired),
    onOpen: T.func.isRequired,
    onClose: T.func.isRequired,
    onNext: T.func.isRequired,
    onPrev: T.func.isRequired,
  })
)

export default enhance(PortfolioItem)
