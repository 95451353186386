import PortfolioPage from './PortfolioPage'
import {graphql} from 'gatsby'

export default PortfolioPage

export const pageQuery = graphql`
  query PortfolioPageTemplate {
    markdownRemark(frontmatter: {templateKey: {eq: "portfolio-page"}}) {
      frontmatter {
        seo {
          title
          description
        }
        items {
          author
          properties
          title
          feature {
            colors {
              ...GatsbyImageColors
            }
            childImageSharp {
              fluid(maxWidth: 308, maxHeight: 308, quality: 95) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          images {
            colors {
              ...GatsbyImageColors
            }
            childImageSharp {
              fluid(maxWidth: 786, quality: 95) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`
